import { useEffect, useState } from "react";

const Slider = ({
  items = [],
  arrowLeft = false,
  arrowRight = false,
  dots = false,
  quantityPerItem = 1,
  previewPrev = false,
  previewNext = false,
  auto = 0,
  showIndex = 0,
  isNota = false,
  styleClass = "",
  type = "",
  loopSlider = true,
  swipe = "",
  onChangeItem = () => {}
}) => {
  const [itemActual, setitemActual] = useState(showIndex);
  const [touchPosition, setTouchPosition] = useState(null);
  
  const circleSlider = () => {
    if(quantityPerItem >= items.length) return defaultSlider();
    const listSlider = [];
    for (let index = 0; index < items.length; index ++) {
      let itemsConcat = [];
      for (let aux = 0; aux < quantityPerItem; aux++) {
        let circularIndex = (index + aux) % items.length;
        items[circularIndex] && itemsConcat.push(items[circularIndex]);
      }
      listSlider.push(itemsConcat);
    }
    return listSlider;
  }
  
  const defaultSlider = () => {
    const listSlider = [];
    for (let index = 0; index < items.length; index += quantityPerItem) {
      let itemsConcat = [];
      for (let aux = index; aux < quantityPerItem + index; aux++) {
        items[aux] && itemsConcat.push(items[aux]);
      }
      listSlider.push(itemsConcat);
    }
    return listSlider;
  }
  
  const autocompleteSlider = () => {
    const listSlider = [];
    for (let index = 0; index < items.length; index += quantityPerItem) {
      let itemsConcat = [];
      if((parseInt(index)+parseInt(quantityPerItem)) > items.length) {
        let itemsRepeat = parseInt(quantityPerItem) - (parseInt(items.length) - parseInt(index));
        let firstIndex = parseInt(index) - parseInt(itemsRepeat);
        for (let aux = firstIndex; aux < items.length; aux++) {
          items[aux] && itemsConcat.push(items[aux]);
        }
      } else {
        for (let aux = index; aux < quantityPerItem + index; aux++) {
          items[aux] && itemsConcat.push(items[aux]);
        }
      }
      listSlider.push(itemsConcat);
    }
    return listSlider;
  }

  const functionMap = {
    autocompleteSlider,
    circleSlider,
    defaultSlider,
  };

  const validateType = functionMap.hasOwnProperty(type) ? type : 'defaultSlider';
  const listSlider = functionMap[validateType]();
  const itemLengh = listSlider.length;

  const next = () => {
    setitemActual((itemActual) => {
      const newIndex =
        itemActual === itemLengh - 1 ? (loopSlider ? 0 : itemActual) : itemActual + 1;
      onChangeItem(newIndex);
      return newIndex;
    });
  };

  const prev = () => {
    setitemActual((itemActual) => {
      const newIndex =
        itemActual === 0 ? (loopSlider ? itemLengh - 1 : itemActual) : itemActual - 1;
      onChangeItem(newIndex);
      return newIndex;
    });
  };

  const dotsClick = (index) => {
    setitemActual(index);
  };

  const handleTouchStart = (e) => {
    let touchDown;
    if (swipe === "vertical") {
      touchDown = e.touches[0].clientY;
    } else {
      touchDown = e.touches[0].clientX;
    }
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    let currentTouch;
    if (swipe === "vertical") {
      currentTouch = e.touches[0].clientY;
    } else {
      currentTouch = e.touches[0].clientX;
    }
    console.log("currentTouch", currentTouch)
    const diff = touchDown - currentTouch;

    if (diff > 10) {
      next();
    }

    if (diff < -10) {
      prev();
    }

    setTouchPosition(null);
  };

  const renderListSlider = listSlider.map((item, index) => {
    return (
      <li
        key={"slide" + index}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        className={
          itemActual === index ? "slide-active slide-item" : "slide-item"
        }
      >
        {itemActual === index && item}
      </li>
    );
  });

  useEffect(() => {
    if (parseInt(auto) > 0) {
      setInterval(() => {
        next();
      }, parseInt(auto) * 1000);
    }
  }, []);

  useEffect(() => {
    setitemActual(showIndex)
  }, [showIndex])

  useEffect(() => {
    typeof window !== "undefined" && window.addEventListener("keyup", (event) => {
      if (event.keyCode === 37) {
        prev();
      }
      if (event.keyCode === 39) {
        next();
      }
      return;
    });
  }, []);

  if (!Array.isArray(items) || itemLengh === 0) return <></>;

  return (
    <ul style={{ width: "100%" }} className={`slide-container${styleClass ? ` ${styleClass}` : ''}`}>
      {previewPrev && (
        <li className="slide-preview-prev">
          {
            listSlider[
            parseInt(itemActual) - 1 >= 0
              ? parseInt(itemActual) - 1
              : parseInt(itemLengh) - 1
            ]
          }
        </li>
      )}
      {renderListSlider}
      {previewNext && (
        <li className="slide-preview-next">
          {
            listSlider[
            parseInt(itemActual) + 1 === parseInt(itemLengh)
              ? 0
              : parseInt(itemActual) + 1
            ]
          }
        </li>
      )}
      {
        itemLengh > 1 && <li className="slide-group-control">

          {arrowLeft && (
            <div className="slide-arrow-left" onClick={ ()=> { isNota? next() : prev() } }>
              {arrowLeft}
            </div>
          )}
          {dots &&
            [...Array(itemLengh)].map((e, i) => (
              <div
                className="slide-dots"
                key={`slideDots${i}`}
                onClick={() => dotsClick(i)}
              >
                {dots}
              </div>
            ))}
          {arrowRight && (
            <div className="slide-arrow-right" onClick={ () => { isNota? prev() : next() } }>
              {arrowRight}
            </div>
          )}

        </li>

      }
    </ul>
  );
};

export default Slider;
